
export default {
  name: 'BaseYavagroHrefCard',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    }
  }
}
