
import { Component, Vue } from 'nuxt-property-decorator'
import HrefCard from './HrefCard.vue'

@Component({
  components: {
    HrefCard
  }
})
export default class extends Vue {
  hrefCards = [
    {
      id: 'href1',
      name: 'Школьникам',
      background:
        'linear-gradient(180deg, #CFFDD9 0%, #E1FFDE 100%), linear-gradient(130.5deg, #FDE2CF -28.36%, #FFFEDE 113.83%)',
      description:
        'Бесплатные тесты по профориентации, актуальные рейтинги вузов и школ, карьерная консультация, помощь в выборе  профессии будущего,  онлайн библиотека, практика и подработка школьникам',
      image: '/images/main/href/1.png',
      href: '/schoolchildren'
    },
    {
      id: 'href2',
      name: 'Абитуриентам',
      background: 'linear-gradient(130.5deg, #FDE2CF -28.36%, #FFFEDE 113.83%)',
      description:
        'Лучшие предложения учебных заведений страны, мнения профессионалов, помощь в выборе  профессии и поступлении, книги, истории успеха, конкурсы и гранты ',
      image: '/images/main/href/2.png',
      href: '/applicants'
    },
    {
      id: 'href3',
      name: 'Студентам',
      background:
        'linear-gradient(0deg, #FAF9DA, #FAF9DA), url(.png), linear-gradient(97.65deg, #FBF6CD 0%, #ECFBCD 100%)',
      description:
        'Мечтаете о стажировке в крупной компании? Воспользуйтесь рейтингом работодателей на платформе, выберите  востребованные профессии будущего, лучшие вакансии и бесплатные курсы. ',
      image: '/images/main/href/3.png',
      href: '/students'
    },
    {
      id: 'href4',
      name: 'Повышение квалификации',
      background: 'linear-gradient(130.5deg, rgba(207, 207, 253, 0.23) -28.36%, rgba(222, 235, 255, 0.65) 113.83%)',
      description:
        'Хотите изменить настоящее, узнать что-то новое, получить второе образование? Используйте один из сервисов платформы по повышению квалификации или закажите карьерную консультацию,  карту личного роста',
      image: '/images/main/href/4.png',
      href: 'https://svoevagro.edwica.ru/?utm_source=referrer&utm_medium=svoevagro&utm_campaign=header'
    }
  ]
}
