
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { HrefCardModel } from '~/model/'
import BaseYavagroHrefCard from '~/components/UI/BaseYavagroHrefCard.vue'

@Component({
  components: {
    BaseYavagroHrefCard
  }
})
export default class extends Vue {
  @Prop({
    default: () => ({
      id: '',
      name: '',
      background: '',
      description: '',
      image: '',
      href: ''
    })
  })
  readonly hrefItem!: HrefCardModel

  goToPage(href: string) {
    this.$router.push(href)
  }
}
